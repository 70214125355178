import axios from "axios";
import {API_URL, DOMAIN } from "@/const";
import formValidationEdit from "../mixins/form/formValidationEdit";

export default {
  mixins: [
    formValidationEdit,
  ],

  data() {
    return {
      prefectures: [],
      legalPersonalityTypes: [],
      cbaItems: [],
      disposalWayTypes: [],
      jwnetItemLicences: [],
      duplicatedNameInfoList: [], //重複する名称情報
    };
  },

  methods: {
    // 都道府県選択肢取得API   DOMAIN.PREFECTURES
    getPrefecturesApi() {
      return axios
        .get(API_URL.DOMAIN.PREFECTURES)
        .then((res) => {
          this.prefectures = res.data.prefectures;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 法人格選択肢取得API   DOMAIN.LEGAL_PERSONALITY_TYPE
    getLegalPersonalityTypesApi() {
      return axios
        .get(API_URL.DOMAIN.LEGAL_PERSONALITY_TYPE)
        .then((res) => {
          this.legalPersonalityTypes = res.data.legalPersonalityTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 許可書用、廃棄物種類選択肢取得API   DOMAIN.JWNET_ITEM_TYPE_LICENCE
    getJwnetItemLicencesApi() {
      return axios
        .get(API_URL.DOMAIN.JWNET_ITEM_TYPE_LICENCE)
        .then((res) => {
          this.jwnetItemLicences = res.data.jwnetItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // CBA品目選択肢取得API   MANIFEST.CBA_ITEMS
    getCbaItemsApi() {
      return axios
        .get(API_URL.MANIFEST.CBA_ITEMS)
        .then((res) => {
          this.cbaItems = res.data.cbaItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 処分方法選択肢取得API   DOMAIN.DISPOSAL_WAY_TYPE
    getDisposalWayTypesApi() {
      return axios
        .get(API_URL.DOMAIN.DISPOSAL_WAY_TYPE)
        .then((res) => {
          this.disposalWayTypes = res.data.disposalWayTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //ファイルアップロードAPI
    uploadApi(file) {
      const params = new FormData();
      params.append("file", file);
      params.append("fileName", file.name);
      return axios
        .post(API_URL.COMMON.FILE_UPLOAD, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return res.data.filePath;
        });
    },

    // 処分事業者情報取得API   DISPOSER.DETAIL + {id}
    getDisposerInfo(disposalCompanyId) {
      return axios
        .get(API_URL.DISPOSER.DETAIL + disposalCompanyId)
        .then((res) => {
          const data = res.data;
          // 企業情報
          this.form = {
            businessCode: data.disposerBaseInfo.businessCode,
            legalPersonalityTypeId: data.disposerBaseInfo.legalPersonalityType.id,
            companyName: data.disposerBaseInfo.name,
            addressInfo: {
              zipCode: data.disposerBaseInfo.zipCode,
              selectedPrefectureTemp: data.disposerBaseInfo.prefectures.name,
              prefecturesId: data.disposerBaseInfo.prefectures.id,
              address: data.disposerBaseInfo.address,
              buildingName: data.disposerBaseInfo.buildingName,
            },
            tel: data.disposerBaseInfo.tel,
            fax: data.disposerBaseInfo.fax,
            staffName: data.staffName,
            staffMail: data.staffMail,
            cbaItemIdList: data.cbaItem.map((item) => { return { cbaItemId: item.id }; }),
            remarks: data.remarks,
            isLicence: !!data.licenceMunicipalityList.length || !! data.licenceCityList,
            disposalLicenceCreateList: [],
            
          };

          // 許可証情報
          if (data.licenceMunicipalityList.flatMap(m => m.disposalLicenceList).length) this.validate.disposalLicenceCreateList = [];
          data.licenceMunicipalityList.forEach(m => {
            m.disposalLicenceList.forEach(licence => {
              const newLicence = {
                wasteTypeId : DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE,
                disposalLicenceId: licence.disposalLicenceId,
                licenceNo: licence.licenceNo,
                licenceExpiredDate: licence.licenceExpiredDate,
                isExcellent: licence.isExcellent,
                licenceFileName: licence.licenceFileName,
                isActive: licence.isActive,
                isUpdate: licence.isUpdate,
                targetJwnetItemList: licence.targetJwnetItemList,
                disposalWayList: [],
              };
              const newLicenceValidation = JSON.parse(JSON.stringify(this.dummyValidate.disposalLicence));

              // 処分方法
              if (licence.disposalWayList.length) newLicenceValidation.disposalWayList = [];
              licence.disposalWayList.forEach(w => {
                const newDisposalWay = {
                  disposalLicenceDisposalWayId: w.disposalLicenceDisposalWayId,
                  disposalWayTypeId: w.disposalWayType.id,
                  disposalDetailWay: w.disposalDetailWay,
                };

                newLicence.disposalWayList.push(newDisposalWay);
                newLicenceValidation.disposalWayList.push(JSON.parse(JSON.stringify(this.dummyValidate.disposalWay)));
              });

              this.form.disposalLicenceCreateList.push(newLicence);
              this.validate.disposalLicenceCreateList.push(newLicenceValidation);
            });
          });

          // 一廃許可証情報
          data.licenceCityList.forEach( lc => {
            lc.disposalLicenceList.forEach(licence => {
              const newLicence = {
                wasteTypeId : DOMAIN.WASTE_TYPE.COMMON_WASTE,
                cityName: lc.cityName,
                disposalLicenceId: licence.disposalLicenceId,
                licenceNo: licence.licenceNo,
                licenceExpiredDate: licence.licenceExpiredDate,
                isExcellent: licence.isExcellent,
                licenceFileName: licence.licenceFileName,
                isActive: licence.isActive,
                isUpdate: licence.isUpdate,
                targetJwnetItemList: licence.targetJwnetItemList,
                disposalWayList: [],
              };
              const newLicenceValidation = JSON.parse(JSON.stringify(this.dummyValidate.disposalLicence));

              // 処分方法
              if (licence.disposalWayList.length) newLicenceValidation.disposalWayList = [];
              licence.disposalWayList.forEach(w => {
                const newDisposalWay = {
                  disposalLicenceDisposalWayId: w.disposalLicenceDisposalWayId,
                  disposalWayTypeId: w.disposalWayType.id,
                  disposalDetailWay: w.disposalDetailWay,
                };

                newLicence.disposalWayList.push(newDisposalWay);
                newLicenceValidation.disposalWayList.push(JSON.parse(JSON.stringify(this.dummyValidate.disposalWay)));
              });

              this.form.disposalLicenceCreateList.push(newLicence);
              this.validate.disposalLicenceCreateList.push(newLicenceValidation);
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 所属による名称が重複チェックAPI
    checkDuplicationName(duplicationNameCheckInfoList) {
      return axios
          .post(API_URL.DUPLICATION.NAME, {duplicationNameCheckInfoList : duplicationNameCheckInfoList})
          .then((res) => {
            this.duplicatedNameInfoList = res.data.duplicatedNameInfoList;
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
};
